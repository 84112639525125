<template>
  <div>
    <div class="padding-sm" style="">排课日历</div>

    <div class="padding-sm " style="display: flex;justify-content: space-between;align-items: center">
      <div>
        <el-date-picker
            v-model="date"
            size="mini"
            type="month"
            @change="onChange"
            :clearable="false"
            format="yyyy-MM"
            value-format="yyyy-MM"
            placeholder="选择月">
        </el-date-picker>
        <el-date-picker
            class="margin-l-sm"
            :picker-options="pickerOptions"
            v-model="dates"
            @change="onChangeDate"
            size="mini"
            :clearable="false"
            type="datetimerange"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
        <el-select class="margin-l-sm" v-model="teacher_uid" placeholder="上课老师"
                   v-if="isManage"
                   size="mini" @change="changeTeacher">
          <el-option
              label="全部"
              value="">
          </el-option>
          <el-option
              v-for="item in teacherList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            <span style="float: left">{{ item.name }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.status==1?'在职':'离职' }}</span>
          </el-option>
        </el-select>
      </div>
    </div>

    <div>
      <div class="el-calendar">

        <div class="el-calendar__header" style="padding: 2px">
          <div class="el-calendar__title">

          </div>
        </div>

        <div class="el-calendar__body">
          <table cellspacing="0" cellpadding="0" class="el-calendar-table" v-loading="loading">
            <thead>
            <th class="text-center">日</th>
            <th class="text-center">一</th>
            <th class="text-center">二</th>
            <th class="text-center">三</th>
            <th class="text-center">四</th>
            <th class="text-center">五</th>
            <th class="text-center">六</th>
            </thead>
            <tbody>
            <tr class="el-calendar-table__row calendar-item" v-for="(item,index) in table" :key="index">
              <td class="current"  v-for="(i,index) in item" :key="index">
                <span class="calendar-day">{{i.month}}-{{i.day}}</span>
                <div class="el-calendar-day"><p class="">
                  <div class="tag teacher"  v-for="(t,index) in i.teacher" :key="index" @click="$router.push('/salesCourseSchedulingDetail/'+t.id)">
                    <div class="tag-bg" :style="{'background':t.color,'border':'1px solid '+t.color}"></div>
                    <span class="tag-name" :style="{'color':t.color==''?'#000':'#fff'}">
                        {{t.tname}} {{t.name}} 第{{t.section}}节课 {{t.start_at}} - {{t.end_at}}
                      </span>
                  </div>
                </p>
                </div>
              </td>
            </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import moment from 'moment';

export default {
  data() {
    return {
      table:[],
      dates:[],
      loading:false,
      date:'',
      teacherList:[],
      teacher_uid:'',
      isManage:false,
      pickerOptions:{
        disabledDate:this.disabledDate
      }
    }
  },
  methods:{
    ...mapActions("courseScheduling", ["getScheduling",'getCourseSchedulingTeacherList']),
    disabledDate(time) {

      let currentMonth = moment(this.date).month();
      let targetMonth = moment(time).month();
      console.log(currentMonth)
      return currentMonth !== targetMonth;
    },
    getList(){
      this.loading = true
      this.getScheduling({
        date:this.date,
        dates:this.dates,
        teacher_uid:this.teacher_uid
      }).then(res => {
        this.table = res.data
      }).finally(()=>{
        this.loading = false
      })
    },
    onChange(e){
      if(e==null) {
        this.$message.error('请选择日期')
        return
      }
      this.dates = [
        moment(e).startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        moment(e).endOf('month').format('YYYY-MM-DD HH:mm:ss')
      ]
      this.date = e
      this.getList()
    },
    onChangeDate(e){
      this.getList()
    },
    changeTeacher(e){
      this.teacher_uid = e
      this.getList()
      console.log(e)
    },
    getTeacherList(){
      this.getCourseSchedulingTeacherList({pageSize:'500'}).then(res => {
        this.teacherList = res.data.list
        this.teacherList.forEach(item =>{
          if(item.uid == this.$store.state.user.info.id && item.is_manage == 1){
            this.isManage = true
          }
        })
      })
    }
  },
  mounted() {
    this.date = moment().month(moment().month()).format('YYYY-MM')
    this.getList()
    this.getTeacherList()

    this.dates = [
      moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
      moment().endOf('month').format('YYYY-MM-DD HH:mm:ss')
    ]

    if(this.$store.state.user.info.role == 1){
      this.isManage = true
    }
  }
}
</script>

<style scoped>

.tag{
  position: relative;
  padding: 5px;
  display: inline-block;
}
.tag-bg{
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  border-radius: 5px;
  opacity: .8;
}
.tag-name{
  position: relative;
  z-index: 1;
  color: #fff;
  font-size: 12px;
}

.el-calendar-day:hover{
  background: #fff;
}
.calendar-item{
  position: relative;
}
.calendar-day{
  display: inline-block;
  margin-top:8px;
  margin-left: 8px;
  font-size: 16px;
  color: #999;
}
.teacher{
  display: block;
  margin: 5px;
  padding: 5px;
  font-size: 14px;
  line-height: 16px;
  border-radius: 5px;
}
.is-selected {
  color: #1989FA;
}

::v-deep .current {
  height: 200px;
  position: relative;
}

::v-deep .el-calendar-day {
  height: auto;
}

.calendar-son-item {
  margin-top: 5px;
}

</style>